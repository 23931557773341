.menu-item:hover {
    background-color: #238fcc;
}
.menu-item-selected {
    background-color: #1f7fb6;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}
.org-card {
    width: 450px;
    height: 450px;
}
.org-card-description {
    position: relative;
    width: 450px;
    height: 150px;
}
.org-card-description:after {
    content: '';
    position: absolute;
    top: 130px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0), white);
}
.office-card {
    height: 170px;
    background-color: #f0f0f0;
    border: 1px solid #efece4;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    border-radius: 10px;
    padding: 10px;
}
.office-card-image {
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
}
.office-card-icon {
    background-image: url(../images/circle-2.png);
    width: 125px;
    height: 125px;
}
.position-gray {
    color: #D0D3D4;
}
.bg-new {
    background-color: #D0D3D4;
}
.sapr-shadow {
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
.unit-content {
}
.unit-overlay {
    position: relative;
}
.unit-expert {
    position: absolute;
    left: 20px;
    top: 1px;
    white-space: nowrap;
    z-index: 1100;
}
.bg-edu {
    background-color: rgba(188, 190, 192, 1);
}
.bg-retraining {
    background-color: rgba(146, 158, 188, 1);
}
.bg-reward {
    background-color: #FADBD8;
}
.bg-training {
    background-color: rgba(188, 190, 192, 1);
}
.bg-eduactresult {
    background-color: rgba(146, 158, 188, 1);
}
.bg-personalcontribution {
    background-color: #FADBD8;
}
.bg-empinfo {
    background-color: #eeeeee;
}
.bg-selected-button {
    color: white;
    background-color: #929ebc;
}
.nav-tabs .nav-item .nav-link {
    background-color: rgba(241, 91, 78, 0.5);
}
.nav-tabs .nav-item .nav-link.active {
    background-color: rgba(241, 91, 78, 1);
    color: #fff;
}
.sapr-header-sidebar {
    margin-left: 15em;
}
.sapr-sidebar {
    width: 15em;
    overflow: hidden;
}
.sapr-cursor {
    cursor: pointer;
}
.sapr-declarations {
    min-width: 250px;
}
.sapr-declaration {
    min-width: 960px;
}
.sapr-declarations-min {
    width: 5em;
}
.sapr-url {
    color: #238fcc !important;
}
.sapr-vertical {
    writing-mode: vertical-rl;
    text-orientation: mixed;
}
.sapr-auth-button {
    width: 16em;
}
.div-visible {
    transition: all 0.4s ease-in-out;
}
.div-darker {
    opacity: 0.2;
}
body {
    background-color: #F0F3F4;
}
